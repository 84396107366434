<template>
  <div class="centered-wrapper container-wrapper">
    <Logo class="logo" />
    <FormWrapper @onSubmit="onSubmit">
      <h2 class="main-title">Авторизация</h2>
      <h3 class="sub-title">Доступ к системе</h3>
      <hr class="u-mt--1 u-mb--1" />
      <Socials />
      <Separator> или </Separator>
      <BaseInput
        label="Введите Логин или Телефон"
        v-model.trim="username"
        placeholder="Введите Логин или Телефон"
        type="email"
        class="u-mb--1"
      />
      <BaseInput
        label="Введите пароль"
        v-model.trim="password"
        placeholder="Введите пароль"
        inputId="password"
        type="password"
        class="u-mb--1"
      />
      <Button width="100%" class="u-mb--1" @click.prevent="onSubmit"> Войти </Button>
      <Button
        width="100%"
        background="--secondary"
        @click.prevent="$router.push({ name: 'loginToken' })"
        >Войти по ЭЦП</Button
      >

      <router-link :to="{ name: 'restoration' }" class="restoration-link link u-mt--2 u-mb--2">
        Забыли пароль?
      </router-link>
      <hr />
      <div class="registration-link u-mt--2">
        Еще не пользователь?
        <router-link :to="{ name: 'registration' }"> Регистрация </router-link>
      </div>
    </FormWrapper>
  </div>
</template>

<script>
import { ref } from "vue";
import { getUrl, isUrlExists, removeUrl } from "@/core/utils/returnUrlController";
import api from "@/core/api";
import { useRouter } from "vue-router";

import FormWrapper from "@/core/components/FormWrapper/FormWrapper.vue";
import BaseInput from "@/core/components/BaseInput/BaseInput.vue";
import Button from "@/core/components/Button/Button.vue";
import Socials from "@/core/components/Socials/Socials.vue";
import Separator from "@/core/components/Separator/Separator.vue";
import { auth } from "@/core/services/auth";

import Logo from "@/assets/icons/logo.svg";

export default {
  components: { Button, FormWrapper, BaseInput, Socials, Separator, Logo },
  setup() {
    const username = ref("");
    const password = ref("");

    const router = useRouter();

    const redirectToBack = (id) => {
      const returnUrl = getUrl(id);
      removeUrl();
      window.open(returnUrl, "_self");
    };

    const getToken = (id) => {
      api.getToken(id).then((resp) => {
        auth.setToken(resp.data.access_token);
        router.push({ name: "profile" });
      });
    };

    const onSubmit = async () => {
      try {
        const formData = new FormData();
        formData.append("username", username.value);

        // console.log(username.value);
        // if( /^\+?7\d{10}$/.test(username.value) ){
        //   password.value = Math.random();
        // }

        formData.append("password", password.value);
        const {
          data: { id },
        } = await api.getAccessToken(formData);

        if (id === "sms_code") {
          return router.push({ name: "sms" });
        }

        if (isUrlExists()) {
          redirectToBack(id);
        } else {
          getToken(id);
        }
      } catch (e) {
        console.error(e);
      }
    };

    if (auth.loggedIn()) {
      router.push({ name: "profile" });
    }

    return {
      username,
      password,
      onSubmit,
    };
  },
};
</script>

<style scoped lang="scss" src="./Login.scss"></style>
