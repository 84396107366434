<template>
  <div class="spcials-wrapper">
    <vk />
    <yandex />
    <facebook />
    <google />
    <apple />
  </div>
</template>

<script>
import vk from "@/assets/icons/vk.svg";
import yandex from "@/assets/icons/yandex.svg";
import facebook from "@/assets/icons/facebook.svg";
import google from "@/assets/icons/google.svg";
import apple from "@/assets/icons/apple.svg";

export default {
  components: { vk, yandex, facebook, google, apple },
};
</script>

<style lang="scss" scoped src="./Socials.scss"></style>
